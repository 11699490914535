@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~@angular/material/prebuilt-themes/rose-red.css';

@import 'table';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
